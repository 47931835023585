import React from 'react'
import Image from '../components/Image'
import AnchorSlug from '../components/AnchorSlug'

const ProductDetails = ({ id, title, children, image, reverse}) => {
  return (
    <section key={id} id={id} className={`relative my-10 md:py-10 py-10 md:px-0 px-4 ${ !reverse ? `md:py-20 bg-gray-100 border border-grey shadow-inner` : ``}`}>
      <div id={id} className={`container md:flex md:p-0 p-4 mx-auto ${ reverse ? `flex-row-reverse` : `flex-row `}`}>
        <div className="md:w-1/2 flex flex-col">
          <div className="w-full md:h-full rounded-lg md:p-10 p-2 detail-image">
            <Image src={image} alt={title} className={`h-full w-full bg-white border border-gray-300 rounded-xl shadow-xl`}/>
          </div>
        </div>
        <div className="md:w-1/2 flex flex-col md:p-8 pt-4 md:text-left text-center justify-center space-y-3">
          <AnchorSlug to={id} title={title} text={title} className="md:m-0 mx-auto"/>
          <div className="flex flex-col space-y-3 font-light text-md">{children}</div>
        </div>
      </div>
    </section>
  )
}

export default ProductDetails